@font-face {
  font-family: "Pacifico";
  src: url('fonts/Pacifico/Pacifico-Regular.ttf') format('truetype')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-primary {
  &:hover {
    border-color: black !important;
  }
}

.ant-btn:hover {
  border-color: black !important;
  color: black !important;
  scale: 1.05;
}